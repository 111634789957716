body {
  font-family: "Quicksand", sans-serif;
}

hr {
  color: #1d1839;
  opacity: .75;
  border-width: 2px;
}

.bg-darken {
  background-color: rgba(0, 0, 0, .3);
  border-radius: 50px;
}

.map-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

blockquote {
  border-left: 5px solid #6bbfa4; /* Color for quote */
  padding-left: 1rem;
  font-style: italic;
  background: #f9f9f9;
}

.border {
  border-color: #ddd !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}


.map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.text-primary {
  color: #00B050;
}

.text-secondary {

  color: #0070C0 !important;
}

.jackie-blue {
  color: #0070C0;

}
.jackie-green {
  color: #00B050;

}
.btn-primary {
  background-color: #00B050 !important;
}

.btn-secondary {
  color: white !important;
  background-color: #0070C0 !important;
}

.btn-outline-secondary {
  border-color: #0070C0;
  color: #0070C0;
}

.border-secondary {
  border-color: #0070C0 !important;
}

.border-primary {
  border-color: #0070C0 !important;

}

.bg-primary {
  background-color: #6bbfa4 !important;
}
.event-card {
  border: 1px solid #dee2e6;
  border-radius: .375rem;
  margin-bottom: 1.5rem;
  padding: 1.25rem;
  box-shadow: 0 1px 2px rgba(0,0,0,.075);
  height: 100%;
  
}
#logo {
  width: 200px;
}

#footer-logo {
  width: 200px;
}

#hp-banner-name {
  margin-top: 150px;
  text-align: center;
}

.hero-section {
  background: url('/public/images/banner6.png') no-repeat center center;
  background-size: cover;
  background-position: top; 
  color: white;
  height: 700px;
  border: #3DB4CD solid 10px;
}

.hero-section-2 {
  background-color: #1d1839;
  height: 250px;
  min-height: 250px;
}

.floating-tab {
  position: fixed;
  right: 0;
  bottom: 0;
}

.fade-in-form {
  opacity: 0;
  transform: scale(0.95);
  animation: fadeIn 0.7s ease-out forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 992px) {
  .w-md-100 {
    width: 100vw;
    margin: 0 auto;
  }

  .text-lg-center {
    text-align: center;
  }
}

@media (max-width: 680px) {
  .hero-section {
    background-image: url('/public/images/banner-mobile4.png');
    height: 500px;
  }

  #banner-header {
    text-align: center;
  }
  
}